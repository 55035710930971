import APIReq from "../_APIReq";
import { API_URL, API_COMMON_ERROR_MSG } from "../../plugins/Const";

const api = {
    request: async () => {
        let retData = {
            isSuccess: false,
            errorMsg: '',
            ssoLogoutUrl: null,
        };
        try {
            const res = await APIReq(`${API_URL}/user/logout`, {}, 'post');
            if (res && res.data && res.data.result) {
                retData.isSuccess = true;
                retData.ssoLogoutUrl = res.data.ssoLogoutUrl

                const form = document.createElement("form")
                form.setAttribute("action", retData.ssoLogoutUrl)
                form.setAttribute("method", "post")
                form.setAttribute("id", "LogoutForm")
                document.body.appendChild(form)
                form.submit()

                document.getElementById("LogoutForm").remove()

            } else if (res && res.data && (res.data.error || res.data.msg)) {
                retData.isSuccess = false;
                retData.errorMsg = res.data.error ? res.data.error : res.data.msg;
            } else {
                retData.isSuccess = false;
                retData.errorMsg = API_COMMON_ERROR_MSG;
            }
        } catch (e) {
            retData.isSuccess = false;
            retData.errorMsg = API_COMMON_ERROR_MSG
        }
        return retData;
    }
};
export default api