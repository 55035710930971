import store from "@/store";

export default async (to, from, next) => {
    if (store.getters['auth/isLogin']) {
        if (store.state.auth.userInfo.userType == 'student') {
            next();
        } else {
            next('main');
        }
    } else {
        next({ name: 'login' });
    }
};