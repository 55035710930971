import APIReq from "../_APIReq";
import { API_URL, API_COMMON_ERROR_MSG } from "../../plugins/Const";

const api = {
    request: async () => {
        let retData = {
            isSuccess: false,
            errorMsg: '',
            data: null,
            ssoLogonUrl: null,
            isEduJoinTermsYn: null,
        };
        try {
            const res = await APIReq(`${API_URL}/user/info`, null, 'post');
            if (res && res.data && res.data.passwordCheckUrl) {
                
                const form = document.createElement("form")
                form.setAttribute("action", res.data.passwordCheckUrl)
                form.setAttribute("method", "post")
                form.setAttribute("id", "pwChkForm")
                document.body.appendChild(form)
                form.submit()

                document.getElementById("pwChkForm").remove()
                return;
            }
            if (res && res.data && res.data.ssoError) {
                window.thisVue.showSsoErrPopup(res.data.ssoError)
            }
            if (res && res.data && res.data.result) {
                retData.isSuccess = true;
                retData.data = res.data.userObj
            } else if (res && res.data && (res.data.error || res.data.msg)) {
                retData.isSuccess = false;
                retData.errorMsg = res.data.error ? res.data.error : res.data.msg;
            } else {
                retData.isSuccess = false;
                retData.errorMsg = API_COMMON_ERROR_MSG;
            }
            if (res && res.data) {
                retData.ssoLogonUrl = res.data ? res.data.ssoLogonUrl : null;
            }
            if (res && res.data) {
                retData.isEduJoinTermsYn = res.data ? res.data.isEduJoinTermsYn : null;
            }
        } catch (e) {
            retData.isSuccess = false;
            retData.errorMsg = API_COMMON_ERROR_MSG
        }
        return retData;
    }
};
export default api