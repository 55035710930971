
import API_Logout from '../../API/sign/logout';
import API_MyInfo from '../../API/sign/myinfo';

// state
export const state = {
    userInfo: null,
    ssoLogonUrl: null,
    isEduJoinTermsYn: null,
};

// getters
export const getters = {
    isLogin: state => state.userInfo ? true : false,
    userInfo: state => state.userInfo,
    ssoLogonUrl: state => state.ssoLogonUrl,
    isEduJoinTermsYn: state => state.isEduJoinTermsYn,
};

// mutations
export const mutations = {
    ['userInfo'](state, userInfo) {
        state.userInfo = userInfo
    },
    ['ssoLogonUrl'](state, ssoLogonUrl) {
        state.ssoLogonUrl = ssoLogonUrl
    },
    ['isEduJoinTermsYn'](state, isEduJoinTermsYn) {
        state.isEduJoinTermsYn = isEduJoinTermsYn
    },
};

// actions
export const actions = {
    async fetchData({ commit }) {
        let res = await API_MyInfo.request();
        console.log(res);
        commit('isEduJoinTermsYn', null);
        commit('ssoLogonUrl', null);
        if (res.isSuccess) {
            commit('userInfo', res.data);
            return true;
        } else {
            commit('userInfo', null)
        }
        commit('ssoLogonUrl', res.ssoLogonUrl);
        commit('isEduJoinTermsYn', res.isEduJoinTermsYn);
        return false;
    },
    async logout({ commit }) {
        await API_Logout.request()
    },
};