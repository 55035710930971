<template>
	<v-app>
		<v-main>
			<transition name="fade-transition" mode="out-in">
				<keep-alive include="KeepAlive">
					<router-view :key="$route.matched[0] ? $route.matched[0].path : ''" />
				</keep-alive>
			</transition>
		</v-main>
		<p-toast v-model="toast.isShow" :msg="toast.msg" :timeout="toast.time" :isIc="toast.isIc" :callback="(isShow) => {
				toast.isShow = isShow;
			}
			" :style="{
		marginBottom: `${footerHeight}px`,
	}" :showClose="toast.showClose" />
		<v-dialog :persistent="pop.persistent" v-model="pop.isShow" v-if="pop.isCreate"
			:width="pxToRem(pop.width ? pop.width : 320)">
			<p-base :title="typeof pop.title === 'undefined' ? '알림' : pop.title" :okTxt="pop.okTxt"
				:cancelTxt="pop.cancelTxt" :onOk="() => {
						if (typeof pop.okAction !== 'undefined') {
							pop.okAction();
						}
						onClosePopupAction(pop, true);
					}
					" :onCancel="() => {
			if (typeof pop.cancelAction !== 'undefined') {
				pop.cancelAction();
			}
			onClosePopupAction(pop, true);
		}
		" :thema="typeof pop.thema === 'undefined' || !pop.thema ? 'default' : pop.thema
		">
				<div v-html="pop.msg" v-if="isHTML(pop.msg)" />
				<template v-else v-for="(msg, index) in pop.msg ? pop.msg.split('\n') : ''">
					{{ msg }}
					<br v-if="pop.msg ? pop.msg.split('\n').length - 1 : 0 > index" :key="index" />
				</template>
			</p-base>
		</v-dialog>
		<v-dialog :persistent="alert.persistent" v-model="alert.isShow" :width="pxToRem(320)">
			<p-base class="pa-6" :title="null" :okTxt="alert.okTxt ? alert.okTxt : '확인'" :cancelTxt="null" :onOk="() => {
					if (typeof alert.okAction !== 'undefined') {
						alert.okAction();
					}
					onClosePopupAction(alert);
				}
				" :thema="typeof alert.thema === 'undefined' ? 'default' : alert.thema">
				<span class="msg-alert" v-html="replaceLnToBr(alert.msg)" />
			</p-base>
		</v-dialog>
		<v-dialog v-if="sso.isCreate" v-model="sso.isShow" persistent :width="pxToRem(isMobileSize ? 335 : 868)">
			<p-sso @onClose="() => {
					onClosePopupAction(sso);
				}
				" />
		</v-dialog>
	</v-app>
</template>
<script>
import PSso from "./popup/pSso.vue";
import pToast from "./popup/pToast.vue";
import Cookies from "@/plugins/CookieUtil";

export default {
	name: "App",
	components: { pToast, PSso },
	data() {
		return {			
			toast: {
				isShow: false,
				msg: null,
				time: 1500,
				isIc: false,
				showClose: true,
			},
			pop: {
				isCreate: false,
				isShow: false,
				persistent: false,
				msg: null,
				width: 320,
			},
			alert: {
				isCreate: false,
				isShow: false,
				persistent: false,
				msg: null,
			},
			sso: {
				isCreate: false,
				isShow: false,
			},
			footerHeight: 0,
			visitorIdentifier: null,
		};
	},
	watch: {},
	created() {	
		window.addEventListener("popstate", () => {
			window.popStateDetected = true;
			// if (this.$store.state.popup.popupList?.length) {
			//   history.pushState(null, null, this.$route.fullPath);
			//   this.onClosePopupAction(
			//     this.$store.state.popup.popupList[
			//       this.$store.state.popup.popupList?.length - 1
			//     ]
			//   );
			// }
		});
		
	},
	mounted() {
		this.setVisitorCookie();
		
		window.backRouter = () => {
			var popupList = this.$store.state.popup.popupList;
			if (popupList && popupList.length > 0) {
				if (popupList[popupList.length - 1].route == this.$route.fullPath) {
					this.onClosePopupAction(popupList[popupList.length - 1]);
					return false;
				}
			}
			if (
				window.backRouterAction &&
				typeof window.backRouterAction === "function" &&
				!window.backRouterAction()
			) {
				return false;
			}
			// this.$router.go(-1);
			return true;
		};

		this.eventOn("SHOW_TOAST", (msg, isIc, showClose, time) => {
			if (this.$el.querySelector("footer")) {
				this.footerHeight = this.$el.querySelector("footer").offsetHeight;
			}
			this.toast.msg = msg;
			if (time) {
				this.toast.time = time;
			}
			if (isIc) {
				this.toast.isIc = isIc;
			}
			this.toast.showClose = showClose;
			this.toast.isShow = true;
			this.$forceUpdate();
		});
		this.eventOn("SHOW_POPUP", (msg) => {
			this.pop = {};
			if (typeof msg == "object") {
				this.pop.msg = msg.msg;
				this.pop.title = msg.title;
				this.pop.okTxt = msg.okTxt;
				this.pop.cancelTxt = msg.cancelTxt;
				this.pop.okAction = msg.okAction;
				this.pop.cancelAction = msg.cancelAction;
				if (msg.width) {
					this.pop.width = msg.width;
				}
				if (typeof msg.persistent != "undefined" && msg.persistent != null) {
					this.pop.persistent = msg.persistent;
				}
				if (msg.thema) {
					this.pop.thema = msg.thema;
				}
			} else if (typeof msg == "string") {
				this.pop = {
					isCreate: false,
					isShow: false,
					msg: msg,
				};
			}
			this.onShowPopupAction(this.pop);
			this.$forceUpdate();
		});
		this.eventOn("SHOW_ALERT", (msg) => {
			this.alert = {};
			if (typeof msg == "object") {
				this.alert.msg = msg.msg;
				this.alert.okTxt = msg.okTxt;
				this.alert.okAction = msg.okAction;

				if (typeof msg.persistent != "undefined" && msg.persistent != null) {
					this.alert.persistent = msg.persistent;
				}
				if (msg.thema) {
					this.alert.thema = msg.thema;
				}
			} else if (typeof msg == "string") {
				this.alert = {
					isCreate: false,
					isShow: false,
					msg: msg,
				};
			}
			this.onShowPopupAction(this.alert);
			this.$forceUpdate();
		});

		this.eventOn("SHOW_NOMAL_TYPE", (msg) => {
			this.onShowPopupAction(this.sso);
		});
		
	},
	activated() { },
	deactivated() { },
	destroyed() {
		delete window.backRouter;
		delete window.pushRouter;
		window.removeEventListener("popstate");
	},
	methods: {
		async setVisitorCookie() {
			
			const cookieName = 'visitor_identifier';
			let visitorIdentifier = await Cookies.get(cookieName);

			if (!visitorIdentifier) {
				// 고유 식별자 생성
				visitorIdentifier = Math.random().toString(36).substr(2, 16);
				
				// 쿠키 만료 시간 설정 (오늘 자정까지)
				const expireTime = new Date();
				expireTime.setHours(23, 59, 59, 999); // 오늘 자정
				Cookies.set(
					cookieName, 
					visitorIdentifier, 
					{ expires: expireTime }
				);
			}
			this.visitorIdentifier = visitorIdentifier;
		},
	},
};

document.onkeydown = function (e) {
	e = e || window.event;
	if (!e.ctrlKey) return;
	var code = e.which || e.keyCode;
	switch (code) {
		case 83:
		case 87:
			e.preventDefault();
			e.stopPropagation();
			break;
	}
};
</script>
<style lang="scss" scoped>
@import "@/sass/app.scss";

.msg-alert {
	font-family: 'NanumSquareB';
	font-size: pxToRem(16px);
	line-height: pxToRem(24px);
	text-align: center;
	word-break: break-all;
	min-height: pxToRem(72px);
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
