var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-main',[_c('transition',{attrs:{"name":"fade-transition","mode":"out-in"}},[_c('keep-alive',{attrs:{"include":"KeepAlive"}},[_c('router-view',{key:_vm.$route.matched[0] ? _vm.$route.matched[0].path : ''})],1)],1)],1),_c('p-toast',{style:({
	marginBottom: `${_vm.footerHeight}px`,
}),attrs:{"msg":_vm.toast.msg,"timeout":_vm.toast.time,"isIc":_vm.toast.isIc,"callback":(isShow) => {
			_vm.toast.isShow = isShow;
		},"showClose":_vm.toast.showClose},model:{value:(_vm.toast.isShow),callback:function ($$v) {_vm.$set(_vm.toast, "isShow", $$v)},expression:"toast.isShow"}}),(_vm.pop.isCreate)?_c('v-dialog',{attrs:{"persistent":_vm.pop.persistent,"width":_vm.pxToRem(_vm.pop.width ? _vm.pop.width : 320)},model:{value:(_vm.pop.isShow),callback:function ($$v) {_vm.$set(_vm.pop, "isShow", $$v)},expression:"pop.isShow"}},[_c('p-base',{attrs:{"title":typeof _vm.pop.title === 'undefined' ? '알림' : _vm.pop.title,"okTxt":_vm.pop.okTxt,"cancelTxt":_vm.pop.cancelTxt,"onOk":() => {
					if (typeof _vm.pop.okAction !== 'undefined') {
						_vm.pop.okAction();
					}
					_vm.onClosePopupAction(_vm.pop, true);
				},"onCancel":() => {
		if (typeof _vm.pop.cancelAction !== 'undefined') {
			_vm.pop.cancelAction();
		}
		_vm.onClosePopupAction(_vm.pop, true);
	},"thema":typeof _vm.pop.thema === 'undefined' || !_vm.pop.thema ? 'default' : _vm.pop.thema}},[(_vm.isHTML(_vm.pop.msg))?_c('div',{domProps:{"innerHTML":_vm._s(_vm.pop.msg)}}):_vm._l((_vm.pop.msg ? _vm.pop.msg.split('\n') : ''),function(msg,index){return [_vm._v(" "+_vm._s(msg)+" "),(_vm.pop.msg ? _vm.pop.msg.split('\n').length - 1 : 0 > index)?_c('br',{key:index}):_vm._e()]})],2)],1):_vm._e(),_c('v-dialog',{attrs:{"persistent":_vm.alert.persistent,"width":_vm.pxToRem(320)},model:{value:(_vm.alert.isShow),callback:function ($$v) {_vm.$set(_vm.alert, "isShow", $$v)},expression:"alert.isShow"}},[_c('p-base',{staticClass:"pa-6",attrs:{"title":null,"okTxt":_vm.alert.okTxt ? _vm.alert.okTxt : '확인',"cancelTxt":null,"onOk":() => {
				if (typeof _vm.alert.okAction !== 'undefined') {
					_vm.alert.okAction();
				}
				_vm.onClosePopupAction(_vm.alert);
			},"thema":typeof _vm.alert.thema === 'undefined' ? 'default' : _vm.alert.thema}},[_c('span',{staticClass:"msg-alert",domProps:{"innerHTML":_vm._s(_vm.replaceLnToBr(_vm.alert.msg))}})])],1),(_vm.sso.isCreate)?_c('v-dialog',{attrs:{"persistent":"","width":_vm.pxToRem(_vm.isMobileSize ? 335 : 868)},model:{value:(_vm.sso.isShow),callback:function ($$v) {_vm.$set(_vm.sso, "isShow", $$v)},expression:"sso.isShow"}},[_c('p-sso',{on:{"onClose":() => {
				_vm.onClosePopupAction(_vm.sso);
			}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }