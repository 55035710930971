import dayjs from 'dayjs';
import ko from 'dayjs/locale/ko';
import { baseRemSize } from '../plugins/Const';

import AppUtil from '../plugins/AppUtil';
import EventBus from '../plugins/EventBus';

dayjs.locale(ko);

export default {
    components: {
        pBase: () => import("../popup/pBase.vue"),
        layHome: () => import('@/layout/home.vue'),
        laySub: () => import('@/layout/sub.vue'),
        cBtn: () => import('@/components/cBtn.vue'),
    },
    props: {},
    data() {
        return {
            dayjs: dayjs,
        };
    },
    computed: {
        isLogin() {
            return this.$store.getters['auth/isLogin'];
        },
        userInfo() {
            return this.$store.getters['auth/userInfo']
        },
        alarmCnt() {
            return this.isLogin ? this.$store.state.auth.userInfo.alarmCount : null
        },
        isMobileSize() {
            return window.thisVue.$vuetify.breakpoint.name === 'sm' || window.thisVue.$vuetify.breakpoint.name === 'xs';
        },
        eduLink() {
            if (window.location.hostname == 'cyverse.edunet.net') {
                return 'https://st.edunet.net'
            } else {
                return 'https://st4.edunet.net'
            }
        },
        ssoLink() {
            if (window.location.hostname == 'cyverse.edunet.net') {
                return 'https://stsso2.edunet.net'
            } else {
                return 'https://stsso4.edunet.net'
            }
        }
    },
    watch: {},
    created() { },
    mounted() { },
    destroyed() { },
    methods: {
        numberWithCommas: AppUtil.numberWithCommas,
        replaceLnToBr: AppUtil.replaceLnToBr,
        getUrlForm: AppUtil.getUrlForm,
        eventOn: AppUtil.eventOn,
        eventOff: AppUtil.eventOff,
        eventEmit: AppUtil.eventEmit,
        sleep: AppUtil.sleep,

        validateEmail: AppUtil.validateEmail,
        validateLoginId: AppUtil.validateLoginId,
        validateLoginPrefix: AppUtil.validateLoginPrefix,
        validatePassword: AppUtil.validatePassword,
        validatePhone: AppUtil.validatePhone,
        validateUrl: AppUtil.validateUrl,
        validateNumber: AppUtil.validateNumber,
        isHTML: AppUtil.isHTML,

        pxToRem(pxSize) {
            let retRem = `${(parseInt(pxSize) / baseRemSize)}rem`;
            return retRem;
        },

        calCreatedDate(val) {
            // ~지금 / 2초~59초 전 / 1분~59분 전 / 1시간~23시간 전 / 1일~6일 전 / 1주 전 / 당해일 경우 월,일만 표시(2월 9일) / 년도 바뀐경우 년,월,일 표시(2020년 2월 9일)
            let nowDate = dayjs()
            let getDate = dayjs(val)

            let diff = Math.floor(nowDate.diff(getDate) / 1000)
            if (diff < 2) {
                return '지금'
            } else if (diff < 60) {
                return `${diff}초 전`
            } else if (diff < (60 * 60)) {
                return `${Math.floor(diff / 60)}분 전`
            } else if (diff < (60 * 60 * 24)) {
                return `${Math.floor(diff / 60 / 60)}시간 전`
            } else if (diff < (60 * 60 * 24 * 7)) {
                return `${Math.floor(diff / 60 / 60 / 24)}일 전`
            } else if (Math.floor(diff / 60 / 60 / 24) == 7) {
                return `1주 전`
            } else {
                return dayjs(val).format('YYYY. MM. DD')
            }
        },
        /**
         * 페이지 전환에 사용할 공통 함수
         * 
         * @param {*}
        */
        replaceRouter(obj) {
            this.$route.meta.internalNavigation = true;
            this.$router.replace(obj, () => {
                this.$route.meta.internalNavigation = false;
            })
            // .catch(error => {
            //     if (error.name != "NavigationDuplicated") {
            //         throw error;
            //     }
            // })
        },
        /**
         * 페이지 전환에 사용할 공통 함수
         * 
         * @param {*}
        */
        pushRouter(obj) {
            this.$route.meta.internalNavigation = true;
            this.$router.push(obj, () => {
                this.$route.meta.internalNavigation = false;
            })
            // .catch(error => {
            //     if (error.name != "NavigationDuplicated") {
            //         throw error;
            //     }
            // })
        },

        /**
         * 페이지 전환에 사용할 공통 함수
         * 
         * @param {*} path /로 시작할 경우 해당 페이지로 /없이 시작할 경우 common 페이지로 라우팅함
         * @param {*} query 페이지 이동시 같이 전달할 쿼리
        */
        goToPage(path, query = {}) {
            this.$route.meta.internalNavigation = true;
            this.$router.push({
                path: path,
                query: query
            }, () => {
                this.$route.meta.internalNavigation = false;
            })
            // .catch(error => {
            //     if (error.name != "NavigationDuplicated") {
            //         throw error;
            //     }
            // })
        },

        /**
         * 페이지 전환에 사용할 공통 함수
         * 
         * @param {*} name 페이지명으로 공통 화면의 경우는 앞에 생략하고 페이지명 입력하면됨
         * @param {*} query 페이지 이동시 같이 전달할 쿼리
         * @param {*} params 페이지 이동시 같이 전달할 파라미터
        */
        goToPageName(name, query = {}, params = {}) {
            this.$route.meta.internalNavigation = true;
            this.$router.push({
                name: name,
                query: query,
                params: params
            }, () => {
                this.$route.meta.internalNavigation = false;
            })
            // .catch(error => {
            //     if (error.name != "NavigationDuplicated") {
            //         throw error;
            //     }
            // })
        },

        showToast(msg, isIc, showClose, time) {
            if (msg) {
                EventBus.emit('SHOW_TOAST', msg, isIc, showClose, time)
            }
        },

        showLoginPopup() {
            EventBus.emit('SHOW_LOGIN_POPUP')
        },

        getDateFormat(val, format = "YYYY-MM-DD HH:mm:ss") {
            let nowTime = val ? dayjs(val) : dayjs()
            let time = nowTime.format(format)
            return time
        },

        showPopup(msg) {
            if (msg) {
                EventBus.emit('SHOW_POPUP', msg)
            }
        },
        showAlert(msg) {
            if (msg) {
                EventBus.emit('SHOW_ALERT', msg)
            }
        },
        showSsoErrPopup(msg) {
            if (msg) {
                if (msg == "사이버스는 교사와 학생만 이용가능합니다.") {
                    EventBus.emit('SHOW_NOMAL_TYPE', msg)
                    return;
                }
                this.showPopup({
                    title: '에듀넷 계정 안내',
                    msg: msg,
                    okTxt: '에듀넷 바로가기',
                    cancelTxt: '닫기',
                    okAction: () => {
                        this.openLinkUrl(this.eduLink)
                    },
                })
            }
        },

        onShowPopupAction(popup, callback = null) {
            popup.route = this.$route.fullPath
            this.$store.dispatch("popup/addPopup", popup);
            // history.pushState(null, null, popup.route);
            Object.defineProperty(popup, 'isShow', {
                get: function () {
                    return this._isShow
                },
                set: function (isShow) {
                    if (!isShow) {
                        const popupList = window.thisVue.$store.getters['popup/popupList']
                        if (popupList[popupList.length - 1] == this) {
                            window.thisVue.$store.dispatch("popup/removePopup", popup);
                        }
                    }
                    window.thisVue.$set(this, '_isShow', isShow)
                },
            });

            if (popup.isCreate) {
                popup.isShow = true;
                if (callback != null) {
                    callback();
                }
            } else {
                popup.isCreate = true;
                window.thisVue.$nextTick(() => {
                    popup.isShow = true;
                    if (callback != null) {
                        callback();
                    }
                });
            }
        },

        onClosePopupAction(popup, isDestroy = false) {
            this.$store.dispatch("popup/removePopup", popup);
            popup.isShow = false;
            if (isDestroy) {
                window.thisVue.$nextTick(() => {
                    popup.isCreate = false;
                });
            }
        },

        avatarUrl(item) {
            try {
                return "/avatar/" + (item.characterName ? item.characterName + ".png" : "")
                // "/avatar/av-" + (item.schoolType ? item.schoolType + '-' : '') + item.characterName + ".png"
            } catch (error) {
                return ""
            }
        },

        log: (msg) => {
            console.log('zz', msg)
        },

        openUnity() {
            if (this.isLogin) {
                // if (window.unityPop && !window.unityPop.closed) {
                //     window.unityPop.focus()
                //     return
                // }
                // if (/Android/i.test(navigator.userAgent)) {
                //     window.unityPop = window.open("/gl/web", "_blank");
                // } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                //     window.unityPop = window.open("/gl/web", "_blank");
                // } else if (/Mobi/i.test(navigator.userAgent)) {
                //     window.unityPop = window.open("/gl/web", "_blank");
                // } else {
                //     window.unityPop = window.open("/gl/web", "_blank");
                // }
                if (/Android/i.test(navigator.userAgent)) {
                    location.href = "/gl/web/"
                } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                    location.href = "/gl/web/"
                } else if (/Mobi/i.test(navigator.userAgent)) {
                    location.href = "/gl/web/"
                } else {
                    location.href = "/gl/web/"
                }
            }
        },
        getAgentOS() {
            if (!("navigator" in window)) {
                return "unknown";
            }
            const platform = (navigator.userAgentData?.platform || navigator.platform)?.toLowerCase();
            if (platform.startsWith("win")) return "win";
            if (platform.startsWith("mac")) return "mac";
            if (platform.startsWith("linux")) return "linux";
            return "unknown";
        },
        openLinkUrl(url) {
            window.open(url)
        },
        goLinkUrl(url) {
            location.href = url
        }
    },
};
