<template>
  <v-layout class="popup-root" :reverse="!isMobileSize">
    <img
      class="ic-x cursor"
      :src="require(`@/assets/images/ic${isMobileSize ? '' : '-pop'}-x.svg`)"
      @click.prevent.stop="
        () => {
          $emit('onClose');
        }
      "
    />
    <v-layout
      column
      class="popup-body"
      align-center
      @mousedown.stop
      @touchstart.stop
    >
      <img
        :src="
          require(`@/assets/images/pop-sso-contents${
            isMobileSize ? '-mb' : ''
          }.png`)
        "
      />
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  destroyed() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.popup-root {
  max-height: 90vh;
  .ic-x {
    width: 40px;
    height: 40px;
    margin-left: 12px;
    @include sm {
      width: 28px;
      height: 28px;
      position: absolute;
      top: 16px;
      right: 24px;
      padding: 4px;
    }
  }
  @include sm {
    position: relative;
  }
  .popup-body {
    width: 816px;
    overflow: auto;
    background: #fff;
    border-radius: pxToRem(16px);
    img {
      width: 100%;
    }
    @include sm {
      padding: pxToRem(48px) pxToRem(20px) pxToRem(42px);
    }
  }
}
</style>