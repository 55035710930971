import axios from "axios";

const request = async (url, data, method = 'post', headers = {}) => {
    try {
        headers['Content-Type'] = "application/json";
        const res = await axios({
            method: method,
            url: url,
            data: method.toLocaleLowerCase() === 'post' ? JSON.stringify(data) : null,
            params: method.toLocaleLowerCase() === 'post' ? null : JSON.stringify(data),
            headers: headers
        });
        if (res) {
            return res;
        }
    } catch (e) {
        window.console.log(e)
    }
    return null;
}

export default request;